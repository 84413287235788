/* eslint-disable */
import React from 'react';

const LoadingSpinner = () => (
  <div className="loading-spinner-overlay">
    <div className="loading-spinner" />
  </div>
);

export default LoadingSpinner;
